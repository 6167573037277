import {
	HomeOutlined,
  UserOutlined,
  FileAddOutlined,
  LogoutOutlined,
} from '@ant-design/icons';

export const config = {
	version: '1.0.5',
	debug: false,
};

export const url = config.debug ? 'http://localhost:3004/v1' : 'https://songrepo.vercel.app/v1';
export const frontUrl = config.debug ? 'http://localhost:3000/' : 'https://songrepo.webomusic.fr/';

export const api = {
	login: { method: 'POST', path: '/auth/login', },
	register: { method: 'POST', path: '/auth/register', },
	logout: { method: 'POST', path: '/auth/logout', },
	refreshTokens: { method: 'POST', path: '/auth/refresh-tokens', },
	musicPublic: { method: 'GET', path: '/music/public/', },
	musicAll: { method: 'GET', path: '/music', },
	musicAdd: { method: 'POST', path: '/music', },
	musicEdit: { method: 'PATCH', path: '/music/', },
	musicDelete: { method: 'DELETE', path: '/music/', },
	musicByYear: { method: 'GET', path: '/music/year/', },
	infoYoutube: { method: 'GET', path: '/info/youtube/', },
	userUpdate: { method: 'PATCH', path: '/user/update', },
}

export const page = {
	'/': { key: 0, label: 'Home', icon: <HomeOutlined />, mustBeLogout: false, mustBeLogged: false },
	'/login': { key: 1, label: 'Login', icon: <UserOutlined />, mustBeLogout: true, mustBeLogged: false },
	'/add': { key: 2, label: 'Add a Song', icon: <FileAddOutlined />, mustBeLogout: false, mustBeLogged: true },
	'/profil': { key: 3, label: 'Profil', icon: <UserOutlined />, mustBeLogout: false, mustBeLogged: true },
	'/logout': { key: 4, label: 'Logout', icon: <LogoutOutlined />, mustBeLogout: false, mustBeLogged: true },
};

export default config;
