import React from 'react';

import './App.css';
import "antd/dist/antd.css";

import HomeScene from './scenes/HomeScene';
import LoginScene from './scenes/LoginScene';
import PublicScene from './scenes/PublicScene';
import LogoutScene from './scenes/LogoutScene';
import ProfilScene from './scenes/ProfilScene';
import AddSongScene from './scenes/AddSongScene';
import NotFoundScene from './scenes/NotFoundScene';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeScene />} />
          <Route path="/login" element={<LoginScene />} />
          <Route path="/add" element={<AddSongScene />} />
          <Route path="/edit" element={<AddSongScene />} />
          <Route path="/profil" element={<ProfilScene />} />
          <Route path="/logout" element={<LogoutScene />} />
          <Route path="/public/:id" element={<PublicScene />} />
          <Route path="*" element={<NotFoundScene />} />  
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default App
