import Template from '../components/Template';
import React, { useContext } from 'react';
import AppContext from "../store";
import {
	DisconnectOutlined
} from '@ant-design/icons';

type NotFoundSceneType = {
  onClick?: Function;
};

const NotFoundScene = (props: NotFoundSceneType) => {
	const {
  	auth,
  } = useContext(AppContext);

	const scene =  (
		<Template logged={!!auth}>
			<div style={{ padding: 24, minHeight: 360 }}>
	      <DisconnectOutlined style={{ fontSize: '55px', marginBottom: '20px' }} />
	      <p>Sorry, we didn't found your page.</p>
	    </div>
		</Template>
	);

	return scene;
}

export default NotFoundScene;