import React, { useState, useEffect, useContext } from 'react';
import Template from '../components/Template';
import MusicBox from '../components/MusicBox';
import DateBox from '../components/DateBox';
import CalendarBox from '../components/CalendarBox';
import AppContext from "../store";
import { Music } from "../store/types";
import { displayDate } from "../store/utils";
import { DatePicker, Button, Switch, message } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import moment from 'moment';

type HomeSceneType = {
  onClick?: Function;
};

const HomeScene = (props: HomeSceneType) => {
  const [modeList, setModeList] = useState(true);
  const navigate = useNavigate();

  const {
    auth,
    musics,
    getMusic,
    deleteMusic,
    loadingMusic,
    selectMusic,
    musicToEdit,
    selectYear,
    year,
    doRefreshTokens,
  } = useContext(AppContext);

  useEffect(() => {
    if (auth && musics === null) {
      getMusic();
      doRefreshTokens();
    }
  }, [auth]);

  useEffect(() => {
    // reset toEdit
    if (musicToEdit)
      selectMusic(null);
    // doRefreshTokens();
  }, []);

  const handleDelete = async (id: string) => {
    const res = await deleteMusic(id);
    if (res && res.code)
      message.error('Fail during process');
    else message.success('Song deleted');
  }

  const sceneLogged = (
    <Template logged={!!auth}>
      <div style={{ padding: 24, minHeight: '90vh', background: 'rgb(0, 45, 87' }}>
        <h2 style={{ marginBottom: 0, fontFamily: 'Shadows Into Light', fontSize: '50px', color: '#ffcb32' }}>SONG REPO</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '1046px', width: '100%', margin: 'auto' }}>
          {!loadingMusic && <div style={{ width: '100%', maxWidth: '200px', borderRadius: '15px', margin: '15px 0', paddingTop: '15px', display:'flex', flexWrap: 'wrap', justifyContent: 'space-around', background: 'white', color: 'black' }}>
            <p>List</p>
            <Switch onChange={() => setModeList(!modeList)}/>
            <p>Calendar</p>
          </div>}
          {!loadingMusic && modeList && musics && <div style={{ width: '100%', maxWidth: '200px', borderRadius: '15px', margin: '15px 10px', background: 'white', color: 'black' }}>
            <DatePicker
              style={{
                fontSize: '22px',
                border: 'none',
                paddingTop: '13px',
                outline: 'none',
                boxShadow: 'none',
              }}
              picker="year"
              onChange={(e:any) => e && selectYear(e.format('YYYY'))}
              defaultValue={moment(year, 'YYYY')} />
          </div>}
        </div>
        
        {!loadingMusic && musics && !musics.length && <p style={{ color: 'white', fontSize: '25px', marginTop: '30px' }}>List empty for this date.</p>}

        {!loadingMusic && !modeList && musics && <CalendarBox
          musics={musics}
          selectMusic={(music: Music) => {
            selectMusic(music);
            navigate("./edit", { replace: true });
          }}
          deleteMusic={handleDelete}
          year={year}
          selectYear={selectYear}
        />}

        {!loadingMusic && modeList && 
          <div style={{
            maxWidth: '1090px',
            margin: 'auto',
          }}>
            {musics && musics.map((elt, key) => 
              <div
                key={key}
                style={{
                  maxWidth: '330px',
                  width: '100%',
                  display: 'inline-block',
                  margin: '10px',
                }}
                >
                {key === 0 && 
                  <DateBox
                    date={displayDate(elt.date)}
                  />}
                {key > 0 && elt.date !== musics[key - 1].date &&
                  <DateBox
                    date={displayDate(elt.date)}
                  />}
                <MusicBox
                  music={elt}
                  selectMusic={(music: Music) => {
                    selectMusic(music);
                    navigate("./edit", { replace: true });
                  }}
                  deleteMusic={handleDelete}
                />
              </div>
            )}
          </div>
        }
        {loadingMusic && <LoadingOutlined style={{ marginTop: '30px', color: 'white', display: 'block', fontSize: '60px' }} />}
      </div>
    </Template>
  )

  const sceneNotLogged = (
    <Template logged={!!auth}>
      <div style={{ padding: 24, minHeight: '90vh', background: 'rgb(0, 45, 87)' }}>
        <h2 style={{ marginBottom: 0, fontFamily: 'Shadows Into Light', fontSize: '50px', color: '#ffcb32' }}>SONG REPO</h2>
        <p style={{ color: 'white' }}>A new way to save your musical discoveries.</p>
        <p style={{ margin: '50px auto', width: '100%', maxWidth: '600px', color: 'white', textAlign: 'center', fontSize: '18px' }}>
          Add your link to the song, add all the informations you need and then get a list of your musics according to the date of discoveries.<br />
          You can display a list or directly view it in a calendar, where you can navigate to the month you want.<br />
          As simple as that, register to try it now !
        </p>
        <Button onClick={() => navigate("./login", { replace: true })} type="primary">Login</Button>
      </div>
    </Template>
  );

  return auth ? sceneLogged : sceneNotLogged;
}

export default HomeScene;