import type { BadgeProps } from 'antd';
import { Badge, Calendar } from 'antd';
import type { Moment } from 'moment';
import React, { useState } from 'react';
import { Music } from "../store/types";
import ModalMusic from './ModalMusic';
import moment from 'moment';

const styles = {
  events: {
	  margin: 0,
	  padding: 0,
	  listStyle: 'none',
  } as React.CSSProperties,
  antBadgeStatus: {
	  width: '100%',
	  overflow: 'hidden',
	  fontSize: '12px',
	  whiteSpace: 'nowrap',
	  textOverflow: 'ellipsis',
  },
	notesMonth: {
	  fontSize: '28px',
	  textAlign: 'center',
	},
	section: {
		fontSize: '28px',
	},
};

const getListData = (value: Moment, musics: Music[]) => {
	const date = value.format('YYYY-MM-DD');
	const listData = [];
	for (let i = 0; i < musics.length; i++) {
		const author = musics[i].author ? musics[i].author[0].toUpperCase() + musics[i].author.substr(1) + ' - ' : '';
		const title = musics[i].title[0].toUpperCase() + musics[i].title.substr(1);
		if (musics[i].date.slice(0, 10) === date)
			listData.push({
				musicId: i,
        type: 'success',
        content: author + title,
			})
	}
  return listData || [];
};

const getMonthData = (value: Moment, musics: Music[]) => {
	const month = value.format('YYYY-MM');
	const listData = [];
	for (let i = 0; i < musics.length; i++) {
		const author = musics[i].author ? musics[i].author[0].toUpperCase() + musics[i].author.substr(1) + ' - ' : '';
		const title = musics[i].title[0].toUpperCase() + musics[i].title.substr(1);
		if (musics[i].date.slice(0, 7) === month)
			listData.push({
				musicId: i,
        type: 'success',
        content: author + title,
			})
	}
  return listData || [];
};

type CalendarBoxType = {
  musics: Music[];
  selectMusic?: Function;
  deleteMusic?: Function;
  year: number | null;
  selectYear: Function;
};


const CalendarBox = (props: CalendarBoxType) => {
	const [music, setMusic] = useState<Music | null>(null);
	const handleSelectSong = (id: number) => {
		setMusic(props.musics[id]);
		showModal();
	}

  const monthCellRender = (value: Moment) => {
    const listData = getMonthData(value, props.musics);
    return (
      <ul style={styles.events}>
        {listData.map((item, id) => (
          <li key={id} onClick={() => handleSelectSong(item.musicId)}>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const dayCellRender = (value: Moment) => {
    const listData = getListData(value, props.musics);
    return (
      <ul style={styles.events}>
        {listData.map((item, id) => (
          <li key={id} onClick={() => handleSelectSong(item.musicId)}>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return <div>
  	<Calendar
	  	dateCellRender={dayCellRender}
	  	monthCellRender={monthCellRender}
	  	defaultValue={moment(props.year, 'YYYY')}
	  	onChange={(e:any) => { 
	  		if (e.format('YYYY') !== props.year)
	  			props.selectYear(e.format('YYYY'));
	  	}}
	  />
	  {music && <ModalMusic
      music={music}
      isModalVisible={isModalVisible}
      handleOk={handleOk}
      handleCancel={handleCancel}
      selectMusic={props.selectMusic}
      deleteMusic={props.deleteMusic}
    />}
	</div>;
};

export default CalendarBox;
