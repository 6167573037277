import {
	Navigate,
} from "react-router-dom";
import Template from '../components/Template';
import React, { useContext, useState } from 'react';
import { Switch } from "antd";
import AppContext from "../store";
import { frontUrl } from "../config";

type ProfilSceneType = {
  onClick?: Function;
};

const ProfilScene = (props: ProfilSceneType) => {
	const {
  	auth,
  	updateUser,
  } = useContext(AppContext);

  const [showPublic, setShowPublic] = useState(auth ? auth.user.showPublic : false);

  const handleChange = async () => {
  	const req = await updateUser(!showPublic);
  	setShowPublic(req.showPublic);
  }

	const scene =  (
		<Template logged={!!auth}>
			<div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
	      <h2>Edit your Profile</h2>
	      <p>Name: {auth && auth.user.name}</p>
	      <p>Email: {auth && auth.user.email}</p>
	      <p>Public profile: {auth && auth.user &&
	      	<Switch defaultChecked={auth.user.showPublic} onChange={handleChange} />
	      }</p>
	      {showPublic && auth && auth.user && <a href={frontUrl + "public/" + auth.user.name}>{frontUrl}public/{auth.user.name}</a>}
	    </div>
		</Template>
	);

	const redirect = (
		<Navigate
      to={`/login`}
    />
	);
	return auth ? scene : redirect;
}

export default ProfilScene;