import React, { useState, useEffect } from 'react';
import { Music } from "../store/types";
import { Button, Modal, Image, notification } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const styles = {
  listInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgb(224, 224, 224)',
    color: 'black',
    padding: '12px 0',
    position: 'relative',
  } as React.CSSProperties,
  listInfoTitle: {
    color: '#777',
    fontSize: '12px',
    marginBottom: 0,
    paddingRight: '15px',
  } as React.CSSProperties,
  listInfoDesc: {
    textTransform: 'capitalize',
    marginBottom: 0,
    maxWidth: '100%',
    wordBreak: 'break-all',
  } as React.CSSProperties,
  tagBox: {
    background: 'rgb(249, 249, 249)',
    border: '1px solid #e6e3e3',
    padding: '2px',
    margin: '2px',
    cursor: 'pointer',
    display: 'inline-block',
  } as React.CSSProperties,
};

type ModalMusicType = {
  onDelete?: Function;
  selectMusic?: Function;
  deleteMusic?: Function;
  isModalVisible: boolean;
  handleOk: any;
  handleCancel: any;
  music: Music;
};

const ModalMusic = (props: ModalMusicType) => {
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [copied, setCopied] = useState(false);
  let timeOut: ReturnType<typeof setTimeout>;

  useEffect(() => {
    if (copied) {
      timeOut = setTimeout(() => setCopied(false), 1000);
    }
    // like componentDidUnMount
    return () => clearTimeout(timeOut);
  }, [copied]);

	const handleEdit = () => {
    if (props.selectMusic) {
      props.selectMusic(props.music);
    }
    props.handleOk();
  }

  const handleDelete = () => {
    if (props.deleteMusic) {
      props.deleteMusic(props.music.id);
    }
    setVisibleDelete(false);
    props.handleOk();
  }

  return (
    <div>
      <Modal
        title=""
        open={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={[
          <Button key={1 } onClick={props.handleCancel} disabled={!props.music.path}>
            <a href={props.music.path} target="_blank" rel="noopener noreferrer">PLAY</a>
          </Button>,
          <Button onClick={() => setVisibleDelete(true)} key={2} type="primary" danger>DELETE</Button>,
          <Button key={3} type="primary" onClick={handleEdit}>
            EDIT
          </Button>,
        ]}
        >
        {props.music.pathMiniature && <Image
          width={200}
          src={props.music.pathMiniature}
        />}
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>SONG</p>
          <p style={styles.listInfoDesc}>{props.music.title}</p>
        </div>
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>ARTIST</p>
          <p style={styles.listInfoDesc}>{props.music.author}</p>
        </div>
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>ALBUM</p>
          <p style={styles.listInfoDesc}>{props.music.album}</p>
        </div>
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>DUREE</p>
          <p style={styles.listInfoDesc}>{props.music.duree ? `${parseInt((props.music.duree / 60) + '')} min ${props.music.duree % 60} sec` : 0}</p>
        </div>
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>PATH</p>
          <CopyToClipboard text={props.music.path}
            onCopy={() => setCopied(true)}>
            <p style={{ background: '#EEE', border: '1px solid #AAA', borderRadius: '4px', padding: '5px', cursor: 'pointer' }}>{props.music.path}</p>
          </CopyToClipboard>
          {copied && <span style={{ color: 'green', position: 'absolute', bottom: '5px', right: '5px' }}>Copied</span>}
        </div>
        <div style={styles.listInfo}>
          <p style={styles.listInfoTitle}>TAGS</p>
          <p style={styles.listInfoDesc}>{props.music.tags.map((elt: string, id: number) => <span key={id} style={styles.tagBox}>{elt}</span>)}</p>
        </div>
        <div style={{ 
          padding: '12px 0',
          borderBottom: '1px solid rgb(224, 224, 224)',
        }}>
          <p style={styles.listInfoTitle}>DESCRIPTION</p>
          <p style={styles.listInfoDesc}>{props.music.description}</p>
        </div>
        <div style={{ 
          padding: '12px 0',
          borderBottom: '1px solid rgb(224, 224, 224)',
        }}>
          <p style={styles.listInfoTitle}>COMPLEMENT</p>
          <p style={styles.listInfoDesc}>{props.music.complement}</p>
        </div>
        <div style={{ 
          padding: '12px 0',
          borderBottom: '1px solid rgb(224, 224, 224)',
        }}>
          <h2 style={styles.listInfoTitle}>LYRICS</h2>
          <p style={styles.listInfoDesc}>{props.music.lyrics}</p>
        </div>
        <div style={styles.listInfo}>
          <h2 style={styles.listInfoTitle}>ACCORDS</h2>
          <p style={styles.listInfoDesc}>{props.music.accords}</p>
        </div>
      </Modal>
      <Modal
        title={"Delete " + props.music.title}
        open={visibleDelete}
        onOk={handleDelete}
        onCancel={() => setVisibleDelete(false)}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>Are you sure to delete the song "<b>{props.music.title}</b>" ?</p>
      </Modal>
    </div>
	)
}

export default ModalMusic;