import React, { useContext } from 'react';
import {
	Navigate,
} from "react-router-dom";
import Template from '../components/Template';
import AppContext from "../store";
import { Button } from "antd";

type LogoutSceneType = {
  onClick?: Function;
};

const LogoutScene = (props: LogoutSceneType) => {
	const {
		auth,
  	doLogout,
  } = useContext(AppContext);

	const scene = (
		<Template logged={!!auth}>
			<div style={{ padding: 24, minHeight: 360 }}>
	      <p>Are you sure to logout ?</p>
	      <Button type="primary" onClick={() => doLogout()}>Logout</Button>
	    </div>
		</Template>
	);

	return auth ? scene : <Navigate
      to={`/`}
    />
}

export default LogoutScene;