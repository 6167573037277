import React from 'react';
import { Form, Input, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

type Props = {
  loading: boolean;
  errMsg: string;
  onSubmit: Function;
};

const SignUpForm = (props: Props) => {
  const onFinish = (values: any) => {
    if (props.onSubmit)
      props.onSubmit(values.email, values.username, values.password);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 10 }}
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      {props.errMsg 
        && <p style={{ textAlign: 'left', color: 'tomato' }}>{props.errMsg}</p>}

      <Form.Item
        label="Username"
        name="username"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: 'Please input your email!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        {props.loading && <Button type="primary">
          <LoadingOutlined />
        </Button>}
        {!props.loading && <Button type="primary" htmlType="submit">
          Submit
        </Button>}
      </Form.Item>
    </Form>
  );
};

export default SignUpForm;