import React, { useState } from 'react';
import { Music } from "../store/types";
import { CaretRightOutlined } from '@ant-design/icons';
import ModalMusic from './ModalMusic';

const styles = {
  box: {
    cursor: 'pointer',
    display: 'inline-block',
    background: 'black',
    borderRadius: '25px',
    maxWidth: '330px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat', 
    height: '280px',
    position: 'relative',
    marginBottom: '10px',
    marginRight: '10px',
  } as React.CSSProperties,
  description: {
    textShadow: '1px 1px 1px black',
    color: 'white',
    textAlign: 'left',
    padding: '15px',
    lineHeight: '21px',
    margin: 'auto',
    marginBottom: '5px',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '25px',
    position: 'absolute',
    bottom: 0,
    left:0,
    right:0,
  } as React.CSSProperties,
  title: {
    color: 'white',
    textTransform: 'capitalize',
  } as React.CSSProperties,
  playBtn: {
    background: '#46a478',
    borderRadius: '50px',
    padding: '8px',
    fontSize: '18px',
    position: 'absolute',
    right: '22px',
    bottom: '22px',
    cursor: 'pointer',
    boxShadow: '1px 1px 1px black',
    color: 'white',
  } as React.CSSProperties,
  listInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid rgb(224, 224, 224)',
    color: 'black',
    padding: '12px 0',
  } as React.CSSProperties,
  listInfoTitle: {
    color: '#777',
    fontSize: '12px',
    marginBottom: 0,
    paddingRight: '15px',
  } as React.CSSProperties,
  listInfoDesc: {
    textTransform: 'capitalize',
    marginBottom: 0,
    maxWidth: '100%',
    wordBreak: 'break-all',
  } as React.CSSProperties,
  tagBox: {
    background: 'rgb(249, 249, 249)',
    border: '1px solid #e6e3e3',
    padding: '2px',
    margin: '2px',
    cursor: 'pointer',
    display: 'inline-block',
  } as React.CSSProperties,
};

type MusicBoxType = {
  onClick?: Function;
  selectMusic?: Function;
  deleteMusic?: Function;
  music: Music;
};

const MusicBox = (props: MusicBoxType) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

	return (
    <div>
  		<div onClick={showModal} style={{ ...styles.box, backgroundImage: 'url(' + props.music.pathMiniature + ')', }}>
        <div style={styles.description}>
          <h2 style={styles.title}>{props.music.title}</h2>
          <h3 style={styles.title}>{props.music.author}</h3>
          <a href={props.music.path} target="_blank" rel="noopener noreferrer"><CaretRightOutlined style={styles.playBtn} /></a>
        </div>

      </div>
      <ModalMusic
        music={props.music}
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
        selectMusic={props.selectMusic}
        deleteMusic={props.deleteMusic}
      />
    </div>
	)
}

export default MusicBox;