import {
  Navigate,
} from "react-router-dom";
import Template from '../components/Template';
import React, { useContext, useState, useEffect } from 'react';
import AppContext from "../store";
import { isYoutube, getYoutubeId } from "../store/utils";
import { Music } from "../store/types";
import { Form, Input, InputNumber, Button, message, Image, DatePicker } from 'antd';
import { LoadingOutlined, SwapOutlined } from '@ant-design/icons';
import moment from 'moment';
import { displayDate } from "../store/utils";

interface FieldData {
  name: string | number | (string | number)[];
  value?: any;
  touched?: boolean;
  validating?: boolean;
  errors?: string[];
}

const layout = {
  labelCol: { span: 4 },
  //wrapperCol: { span: 16 },
};

type AddSongSceneType = {
  onClick?: Function;
};

const AddSongScene = (props: AddSongSceneType) => {
  const {
    auth,
    getInfoYT,
    addMusic,
    loadingMusic,
    musicToEdit,
    editMusic,
    selectYear,
  } = useContext(AppContext);

  const [isEdit, setIsEdit] = useState(musicToEdit ? true : false);
  const [urlLoading, setUrlLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [title, setTitle] = useState<FieldData>({ name: ['title'], value: '' });
  const [author, setAuthor] = useState<FieldData>({ name: ['author'], value: '' });
  const [album, setAlbum] = useState<FieldData>({ name: ['album'], value: '' });
  const [date, setDate] = useState<FieldData>({ name: ['date'], value: moment() });
  const [description, setDescription] = useState<FieldData>({ name: ['description'], value: '' });
  const [duree, setDuree] = useState<FieldData>({ name: ['duree'], value: 0 });
  const [keywords, setKeywords] = useState<FieldData>({ name: ['keywords'], value: '' });
  const [image, setImage] = useState('');
  const [complement, setComplement] = useState<FieldData>({ name: ['complement'], value: '' });
  const [lyrics, setLyrics] = useState<FieldData>({ name: ['lyrics'], value: '' });
  const [doublons, setDoublons] = useState<FieldData>({ name: ['doublons'], value: [] });

  const [form] = Form.useForm();

  let timeOut: ReturnType<typeof setTimeout>;


  useEffect(() => {
    if (isEdit && musicToEdit)
      loadMusic(musicToEdit);
    // like componentDidUnMount
    return () => clearTimeout(timeOut);
  }, []);


  const loadMusic = (music: Music) => {
    setUrl(music.path);
    setTitle({ name: ['title'], value: music.title });
    setAuthor({ name: ['author'], value: music.author });
    setAlbum({ name: ['album'], value: music.album });
    setDescription({ name: ['description'], value: music.description });
    setDuree({ name: ['duree'], value: music.duree });
    setKeywords({ name: ['keywords'], value: music.tags.join(', ') });
    setImage(music.pathMiniature);
    //console.log({ music });
    setDate({ name: ['date'], value: moment(music.date, 'YYYY-MM-DD') });
    setComplement({ name: ['complement'], value: music.complement });
    setLyrics({ name: ['lyrics'], value: music.lyrics });
  }

  const onFinish = async (values: any) => {
    const data: Music = {
      path: url,
      pathMiniature: image,
      title: values.title,
      author: values.author,
      album: values.album,
      description: values.description,
      duree: parseInt(values.duree),
      tags: values.keywords.split(','),
      date: values.date.format('YYYY-MM-DD'),
      complement: values.complement ? values.complement : '',
      lyrics: values.lyrics,
    };

    let res;
    if (isEdit && musicToEdit) {
      data['id'] = musicToEdit['id'];
      res = await editMusic(data);
    } else {
      res = await addMusic(data);
    }

    if (res) { // reset fields
      resetForm();
      message.success('Song ' + (isEdit ? ' edited' : ' added'));
      selectYear(values.date.format('YYYY'));
      if (isEdit) setIsEdit(false);
    }
    else message.error('Fail during process');
  };

  const resetForm = () => {
    form.resetFields();
    setUrl('');
    setImage('');
    setTitle({ name: ['title'], value: '' });
    setAuthor({ name: ['author'], value: '' });
    setAlbum({ name: ['album'], value: '' });
    setDescription({ name: ['description'], value: '' });
    setDate({ name: ['date'], value: moment() });
    setDuree({ name: ['duree'], value: 0 });
    setKeywords({ name: ['keywords'], value: '' });
    setComplement({ name: ['complement'], value: '' });
    setLyrics({ name: ['lyrics'], value: '' });
    setDoublons({ name: ['doublons'], value: [] });
  }

  const swapTitleAuthor = () => {
    const titleTmp = title.value;
    setTitle({ name: ['title'], value: author.value });
    setAuthor({ name: ['author'], value: titleTmp });
  }

  const handleUrl = (value: string) => {
    setUrl(value);
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(async () => {
      setUrlLoading(true);
      // check if youtube
      if (isYoutube(value)) {
        const idYt = getYoutubeId(value);
        const data = await getInfoYT(idYt);

        if (data && data.title) {
          const splitTitle1 = data.title.split('–');
          const splitTitle2 = data.title.split('-');
          if (splitTitle1.length > 1) {
            setTitle({ name: ['title'], value: splitTitle1[1].trim() });
            setAuthor({ name: ['author'], value: splitTitle1[0].trim() });
          } else if (splitTitle2.length > 1) {
            setTitle({ name: ['title'], value: splitTitle2[1].trim() });
            setAuthor({ name: ['author'], value: splitTitle2[0].trim() });
          } else {
            setTitle({ name: ['title'], value: data.title });
          }

          setDuree({ name: ['duree'], value: data.duration + "" });
          setDescription({ name: ['description'], value: data.description });
          setKeywords({ name: ['keywords'], value: data.keywords });
          setImage(data.image);
          setUrl('https://youtu.be/' + idYt);
          setDoublons({ name: ['doublons'], value: data.doublons });
        }
      }
      setUrlLoading(false);
    }, 1000);
  };

  const scene = (
    <Template logged={!!auth}>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        {!isEdit && <h1>Add a Song</h1>}
        {isEdit && <h1>Edit a Song</h1>}
        <Form
          {...layout}
          name="nest-messages"
          onFinish={onFinish}
          form={form}
          fields={[
            title,
            author,
            album,
            duree,
            description,
            keywords,
            date,
            complement,
            lyrics,
          ]}
          >
          <Form.Item name={['url', 'url']} label="Url">
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <Input
                value={url}
                style={{ flex: 5 }}
                onChange={(e) => handleUrl(e.target.value)}
              />
              {urlLoading && <LoadingOutlined style={{ flex: 1, display: 'block', fontSize: '25px', marginLeft: '10px' }} />}
            </div>
          </Form.Item>
          {doublons && doublons.value.length > 0 
            && <div>
              <h1>Doublons!</h1>
              {doublons.value.map((elt:any, i: number) => <div key={i} style={{
                background: '#19181e',
                padding: '5px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                border: '2px solid black',
                borderRadius: '5px',
                color: 'white',
                marginBottom: '15px',
              }}>
                {elt.pathMiniature && <Image
                  width={100}
                  src={elt.pathMiniature}
                />}
                <div style={{ display: 'flex', padding: 5, textAlign: 'left' }}>
                  <div style={{ padding: 5 }}>
                    <h3 style={{ color: 'white', }}><span style={{ fontSize: '12px' }}>Link:</span> {elt.path.slice(8)}</h3>
                    <h3 style={{ color: 'white', }}><span style={{ fontSize: '12px' }}>Title:</span> {elt.title}</h3>
                    <h3 style={{ color: 'white', }}><span style={{ fontSize: '12px' }}>Author:</span> {elt.author ? elt.author : 'n/a'}</h3>
                  </div>
                  <div style={{ padding: 5 }}>
                    <h3 style={{ color: 'white', }}><span style={{ fontSize: '12px' }}>Duration:</span> {parseInt((elt.duree / 60) + '')} min {elt.duree % 60} sec</h3>
                    <h3 style={{ color: 'white', }}><span style={{ fontSize: '12px' }}>Added:</span> {displayDate(elt.date)}</h3>
                  </div>
                </div>
              </div>)}
            </div>}
          <div className="divider" style={{ border: '4px solid #e0e0e0', margin: 'auto', marginBottom: '30px' }}></div>
          {image && <Form.Item name={['image']} label="Miniature">
            <Image
              width={200}
              src={image}
            />
          </Form.Item>}
          <Form.Item name={['title']} label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name={['author']} label="Author">
            <Input />
          </Form.Item>
          <Form.Item label="Swap">
            <Button type="primary" onClick={() => swapTitleAuthor()}><SwapOutlined /></Button>
          </Form.Item>
          <Form.Item name={['album']} label="Album">
            <Input />
          </Form.Item>
          <Form.Item name={['description']} label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={['duree']} label="Duration (s)">
            <InputNumber style={{ width: '100%' }}/>
          </Form.Item>
          <Form.Item name={['keywords']} label="Keywords">
            <Input />
          </Form.Item>
          <Form.Item name={['date']} label="Date Added" rules={[{ required: true }]}>
            <DatePicker style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name={['complement']} label="Complement">
            <Input.TextArea />
          </Form.Item>
          <Form.Item name={['lyrics']} label="Lyrics">
            <Input.TextArea />
          </Form.Item>
          <Form.Item label=".">
            {!loadingMusic && <Button type="primary" htmlType="submit" style={{ width: '100%', height: '50px', borderRadius: '3px' }}>
              Submit
            </Button>}
            {loadingMusic && <Button type="primary" style={{ width: '100%', height: '50px', borderRadius: '3px' }}>
              <LoadingOutlined style={{ flex: 1, display: 'block', fontSize: '25px', marginLeft: '10px' }} />
            </Button>}
          </Form.Item>
        </Form>
      </div>
    </Template>
  );

  const redirect = (
    <Navigate
      to={`/login`}
    />
  );
  return auth ? scene : redirect;
}

export default AddSongScene;