import React from 'react';

const styles = {
  box: {
    borderRadius: '5px',
    background: 'rgb(253, 92, 64)',
    padding: '5px',
    paddingTop: '20px',
    maxWidth: '100px',
    position: 'relative',
    marginBottom: '10px',
  } as React.CSSProperties,
  head1: {
    position: 'absolute',
    top: '-5px',
    left: '70px',
    background: 'white',
    padding: '5px 2px',
  } as React.CSSProperties,
  head2: {
    position: 'absolute',
    top: '-5px',
    left: '25px',
    background: 'white',
    padding: '5px 2px',
  } as React.CSSProperties,
  description: {
    borderRadius: '5px',
    background: 'white',
    paddingTop: '10px',
    paddingBottom: '5px',
    color: 'black',
    lineHeight: '15px',
  } as React.CSSProperties,
};

type DateBoxType = {
  date: string;
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function DateBox(props: DateBoxType) {
  const date = props.date.split('/');
	return (
		<div style={styles.box}>
      <div style={styles.head1}></div>
      <div style={styles.head2}></div>
      <div style={styles.description}>
        <h2>{date[0]}</h2>
        <h3>{months[parseInt(date[1]) - 1]}</h3>
      </div>
    </div>
	)
}

export default DateBox;