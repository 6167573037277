import {
	Navigate,
} from "react-router-dom";
import React, { useState, useContext } from 'react';
import Template from '../components/Template';
import LoginForm from '../components/LoginForm';
import SignUpForm from '../components/SignUpForm';
import { Tabs } from "antd";
import AppContext from "../store";

const { TabPane } = Tabs;

type LoginSceneType = {
  onClick?: Function;
};

const LoginScene = (props: LoginSceneType) => {
  const [activeKey, setActiveKey] = useState("1");

	const {
  	doLogin,
  	doRegister,
  	loadingAuth,
  	errorSignIn,
  	errorSignUp,
  	auth,
  } = useContext(AppContext);

	const scene = (
		<Template>
			<div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
	      <Tabs onChange={(key) => setActiveKey(key)} activeKey={activeKey}>
			    <TabPane tab="Sign In" key="1">
			      <LoginForm
			      	loading={loadingAuth}
			      	errMsg={errorSignIn}
			      	onClickSignUp={() => setActiveKey("2")}
			      	onSubmit={doLogin}
			      />
			    </TabPane>
			    <TabPane tab="Sign Up" key="2">
			      <SignUpForm
			      	loading={loadingAuth}
			      	errMsg={errorSignUp}
			      	onSubmit={doRegister}
			      />
			    </TabPane>
			  </Tabs>
	    </div>
	  </Template>
	);

	return !auth ? scene : <Navigate
      to={`/`}
    />
}

export default LoginScene;