import React, {useEffect, createContext, useState} from "react";

import { api, url } from '../config';
import { request } from './utils';
import { storageData, getStorage, rmStorage } from './localStorage';
import { Auth, Music } from './types';

interface IContextStore {
    auth: Auth | null;
    musics: Music[] | null;
    musicsPublic: Music[] | null;
    year: number | null;
    musicToEdit: Music | null;
    selectMusic: Function;
    selectYear: Function;
    selectYearPublic: Function;
    doRegister: Function;
    doLogin: Function;
    doLogout: Function;
    doRefreshTokens: Function;
    getHome: Function;
    getMusic: Function;
    getMusicPublic: Function;
    addMusic: Function;
    editMusic: Function;
    deleteMusic: Function;
    updateMusic: Function;
    getYears: Function;
    getYear: Function;
    getInfoYT: Function;
    deleteYear: Function;
    updateUser: Function;
    
    // HomeScene + Add/EditSongScene
    loadingMusic: boolean;

    //LoginScene
    loadingAuth: boolean;
    errorSignIn: string;
    errorSignUp: string;

    // menu
    menuOpen: boolean;
    setMenuOpen: Function;
};

const AppContext = createContext({
    auth: null,
    musics: null,
    musicsPublic: null,
    year: null,
    musicToEdit: null,
    selectMusic: () => {},
    selectYear: () => {},
    selectYearPublic: () => {},
    doRegister: () => {},
    doLogin: () => {},
    doLogout: () => {},
    doRefreshTokens: () => {},
    getHome: () => {},
    getMusic: () => {},
    getMusicPublic: () => {},
    addMusic: () => {},
    editMusic: () => {},
    deleteMusic: () => {},
    updateMusic: () => {},
    getYears: () => {},
    getYear: () => {},
    getInfoYT: () => {},
    deleteYear: () => {},
    updateUser: () => {},
    loadingMusic: false,
    loadingAuth: false,
    errorSignIn: '',
    errorSignUp: '',
    menuOpen: false,
    setMenuOpen: () => {},
} as IContextStore);

export const AppContextProvider = (props: any) => {
    const [auth, setAuth] = useState<Auth | null>(null);
    const [musics, setMusics] = useState<Music[] | null>(null);
    const [musicsPublic, setMusicsPublic] = useState<Music[] | null>(null);
    const [year, setYear] = useState<number | null>(null);
    const [musicToEdit, setMusicToEdit] = useState<Music | null>(null);
    const [loadingAuth, setLoadingAuth] = useState(false);
    const [loadingMusic, setLoadingMusic] = useState(false);
    const [errorSignIn, setErrorSignIn] = useState('');
    const [errorSignUp, setErrorSignUp] = useState('');
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        const initStore = async () => {
            const auth = await getStorage('auth');
            if (auth) {
                await setAuth(auth);
            }
            const year = new Date().getFullYear();
            setYear(year);
        }

        initStore();
    }, []);

    const selectMusic = async (music:Music) => {
        await setMusicToEdit(music);
    }
    const selectYear = async (year:number) => {
        await setYear(year);
        await getYear(year);
    }
    const selectYearPublic = async (name: string, year:number) => {
        await setYear(year);
        await getMusicPublic(name, year);
    }

    const doRegister = async (email: string, name: string, password: string) => {
        setLoadingAuth(true);
        setErrorSignUp('');
        const data = JSON.stringify({ email, name, password });
        const req = await request(
            url + api.register.path,
            api.register.method,
            data);

        setLoadingAuth(false);
        if (!req || req.code)
            setErrorSignUp(req.message ? req.message : 'Request failed.');
        else {
            await storageData('auth', req);
            setAuth(req);
        }
    }

    const doLogin = async (e:string, p:string) => {
        setLoadingAuth(true);
        setErrorSignIn('');
        const data = JSON.stringify({ email: e, password: p });
        const req = await request(
            url + api.login.path,
            api.login.method,
            data);

        setLoadingAuth(false);
        if (!req || req.code)
            setErrorSignIn(req.message ? req.message : 'Request failed.');
        else {
            await rmStorage('auth');
            await storageData('auth', req);
            setAuth(req);
        }
    }

    const doLogout = async () => {
        if (!auth || !auth.tokens) return true;
        const data = JSON.stringify({ refreshToken: auth.tokens.refresh.token })
        await request(
            url + api.logout.path,
            api.logout.method,
            data);
        setAuth(null);
        rmStorage('auth');
        return true;
    }
    const doRefreshTokens = async () => {
        if (!auth || !auth.tokens) return true;
        const data = JSON.stringify({ refreshToken: auth.tokens.refresh.token })
        const req = await request(
            url + api.refreshTokens.path,
            api.refreshTokens.method,
            data);
        if (req && !req.code) {
            const authEdit = auth;
            authEdit.tokens = req;
            setAuth(authEdit);
            storageData('auth', authEdit);
        } else if (req && req.code) {
            await setAuth(null);
            await rmStorage('auth');
        }
        return true;
    }
    const getHome = () => {
        console.log('getHome');
    }
    const getMusic = async () => {
        if (!auth || !auth.tokens) return true;
        setLoadingMusic(true);
        const req = await request(
            url + api.musicAll.path,
            api.musicAll.method,
            null,
            auth.tokens.access.token);
        if (req && req.musics) {
            setMusics(req.musics);
        } else {
            setAuth(null);
            rmStorage('auth');
        }

        setLoadingMusic(false);
        return true;
    }
    const getMusicPublic = async (id: string, year: number | null = null) => {
        setLoadingMusic(true);
        const req = await request(
            url + api.musicPublic.path + id + (year ? '/' + year : ''),
            api.musicPublic.method,
            null);
        if (req && !req.code)
            setMusicsPublic(req.musics);
        setLoadingMusic(false);
        return true;
    }
    const addMusic = async (data: Music) => {
        setLoadingMusic(true);
        if (!auth || !auth.tokens) return true;
        const req = await request(
            url + api.musicAdd.path,
            api.musicAdd.method,
            JSON.stringify(data),
            auth.tokens.access.token);
        if (req && req.code && req.code === 401) {
            setAuth(null);
            rmStorage('auth');
        } else if (req && req.code) {
            console.log('Error add music');
            setLoadingMusic(false);
            return false;
        }
        setLoadingMusic(false);
        return true;
    }
    const editMusic = async (data: Music) => {
        setLoadingMusic(true);
        if (!auth || !auth.tokens) return false;
        const id = data.id;
        delete data['id'];
        const req = await request(
            url + api.musicEdit.path + id,
            api.musicEdit.method,
            JSON.stringify(data),
            auth.tokens.access.token);
        if (req && req.code && req.code === 401) {
            setAuth(null);
            rmStorage('auth');
        } else if (req && req.code) {
            console.log('Error edit music');
            setLoadingMusic(false);
            return false;
        }
        setMusicToEdit(null);
        setLoadingMusic(false);
        getMusic();
        return true;
    }

    const deleteMusic = async (id: string) => {
        //setLoadingMusic(true);
        if (!auth || !auth.tokens) return false;
        const req = await request(
            url + api.musicDelete.path + id,
            api.musicDelete.method,
            null,
            auth.tokens.access.token);
        if (req && req.code && req.code === 401) {
            setAuth(null);
            rmStorage('auth');
        } else if (req && req.code) {
            console.log('Error delete music');
            //setLoadingMusic(false);
            return false;
        }
        //setLoadingMusic(false);
        const currentYear = new Date().getFullYear();
        getYear(year ? year : currentYear);
        return true;
    }
    const updateMusic = () => {
        console.log('updateMusic');
    }
    const getYears = () => {
        console.log('getYears');
    }
    const getYear = async (year: number) => {
        if (!auth || !auth.tokens) return false;
        setLoadingMusic(true);
        const req = await request(
            url + api.musicByYear.path + year,
            api.musicByYear.method,
            null,
            auth.tokens.access.token);
        if (req && req.code && req.code === 401) {
            setAuth(null);
            rmStorage('auth');
        } else if (req.musics)
            setMusics(req.musics);
        setLoadingMusic(false);
        return req && req.data ? req.data : req;
    }
    const getInfoYT = async (id: string) => {
        if (!auth || !auth.tokens) return null;
        const req = await request(
            url + api.infoYoutube.path + id,
            api.infoYoutube.method,
            null,
            auth.tokens.access.token);
        return req && req.data ? req.data : req;
    }
    const deleteYear = () => {
        console.log('deleteYear');
    }
    const updateUser = async (showPublic: boolean) => {
        if (!auth || !auth.tokens) return false;
        const req = await request(
            url + api.userUpdate.path,
            api.userUpdate.method,
            JSON.stringify({ showPublic }),
            auth.tokens.access.token);
        if (req && req.code && req.code === 401) {
            setAuth(null);
            rmStorage('auth');
        } else if (req && req.data) {
            const authEdit = auth;
            authEdit.user = req.data;
            await rmStorage('auth');
            await storageData('auth', authEdit);
            setAuth(authEdit);
        }
        return req && req.data ? req.data : req;
    }

    return (
        <AppContext.Provider
            value={{
                auth,
                musics,
                musicsPublic,
                year,
                musicToEdit,
                selectMusic,
                selectYear,
                selectYearPublic,
                doRegister,
                doLogin,
                doLogout,
                doRefreshTokens,
                getHome,
                getMusic,
                getMusicPublic,
                addMusic,
                editMusic,
                deleteMusic,
                updateMusic,
                getYears,
                getYear,
                getInfoYT,
                deleteYear,
                updateUser,
                loadingMusic,
                loadingAuth,
                errorSignIn,
                errorSignUp,
                menuOpen,
                setMenuOpen,
            }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default AppContext;