import React, { useContext } from 'react';
import { page, config } from "../config";
import { PageObj } from "../store/types";
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import AppContext from "../store";

import './template.css';
import type { MenuProps } from 'antd';
import { Layout, Menu } from 'antd';

const { Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: string,
  key: number,
  icon?: JSX.Element,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

type Props = {
  children: JSX.Element,
  logged?: boolean,
};

const Template = (props: Props) => {
  const {
    menuOpen,
    setMenuOpen,
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();
  const pageObj: PageObj = page;
  const menuItems = Object.keys(pageObj)
    .filter(e => pageObj[e].mustBeLogout ? !props.logged : !pageObj[e].mustBeLogout)
    .filter(e => pageObj[e].mustBeLogged ? props.logged : !pageObj[e].mustBeLogged)
    .map(e => 
    getItem(pageObj[e].label, pageObj[e].key, pageObj[e].icon));

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sider collapsible collapsed={!menuOpen} onCollapse={() => setMenuOpen(!menuOpen)}>
        <div className="logo" />
        <Menu
          onClick={(item: any) => navigate(Object.keys(pageObj)[item.key], { replace: true })}
          theme="dark"
          defaultSelectedKeys={[pageObj[location.pathname] ? '' + pageObj[location.pathname].key : '-1']}
          mode="inline"
          items={menuItems}
        />
      </Sider>
      <Layout className="site-layout">
        <Content>
          {props.children}
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <p style={{ margin: 0 }}>SongRepo - <a href="https://github.com/VBoureaud">VBoureaud</a></p>
          <p style={{ margin: 0 }}>v{config.version}</p>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Template;
