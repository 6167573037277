import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import MusicBox from '../components/MusicBox';
import DateBox from '../components/DateBox';
import CalendarBox from '../components/CalendarBox';
import AppContext from "../store";
import { displayDate } from "../store/utils";
import { Layout, DatePicker, Switch } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { frontUrl } from '../config';
const { Footer } = Layout;

type PublicSceneType = {
  onClick?: Function;
};

const PublicScene = (props: PublicSceneType) => {
  const [modeList, setModeList] = useState(true);
  const { id } = useParams();

  const {
    musicsPublic,
    getMusicPublic,
    loadingMusic,
    year,
    selectYearPublic,
  } = useContext(AppContext);

  useEffect(() => {
    if (musicsPublic === null && id)
      getMusicPublic(id);
  }, []);

  const scenePublic = (
      <div>
        <div style={{ padding: 24, minHeight: '90vh', background: 'rgb(0, 45, 87)' }}>
          <a href={frontUrl}><h2 style={{ marginBottom: 0, fontFamily: 'Shadows Into Light', fontSize: '50px', color: '#ffcb32' }}>SONG REPO</h2></a>
          {id && <h4 style={{ color: 'white' }}>{id[0].toUpperCase() + id.substr(1)}'s musics</h4>}
          <div style={{ display: 'flex', flexWrap: 'wrap', maxWidth: '1036px', width: '100%', margin: 'auto' }}>
            <div style={{ width: '100%', maxWidth: '200px', borderRadius: '15px', margin: '15px 0', paddingTop: '15px', display:'flex', flexWrap: 'wrap', justifyContent: 'space-around', background: 'white', color: 'black' }}>
              <p>List</p>
              <Switch onChange={() => setModeList(!modeList)}/>
              <p>Calendar</p>
            </div>
            {!loadingMusic && modeList && musicsPublic && <div style={{ width: '100%', maxWidth: '200px', borderRadius: '15px', margin: '15px 10px', background: 'white', color: 'black' }}>
              <DatePicker
                style={{
                  fontSize: '22px',
                  border: 'none',
                  paddingTop: '13px',
                  outline: 'none',
                  boxShadow: 'none',
                }}
                picker="year"
                onChange={(e:any) => e && selectYearPublic(id, e.format('YYYY'))}
                defaultValue={moment(year, 'YYYY')} />
            </div>}
          </div>
          
          {!loadingMusic && musicsPublic && !musicsPublic.length && <p style={{ color: 'white', fontSize: '25px', marginTop: '30px' }}>List empty for this date.</p>}

          {!loadingMusic && !modeList && musicsPublic && <CalendarBox
            musics={musicsPublic}
            year={year}
            selectYear={(year: number) => selectYearPublic(id, year)}
          />}

          {!loadingMusic && modeList && musicsPublic && musicsPublic.map((elt, key) => 
            <div
              key={key}
              style={{
                maxWidth: '330px',
                width: '100%',
                display: 'inline-block',
                margin: '10px',
              }}
              >
              {key === 0 && 
                <DateBox
                  date={displayDate(elt.date)}
                />}
              {key > 0 && elt.date !== musicsPublic[key - 1].date &&
                <DateBox
                  date={displayDate(elt.date)}
                />}
              <MusicBox
                music={elt}
              />
            </div>
          )}
          {loadingMusic && <LoadingOutlined style={{ marginTop: '30px', color: 'white', display: 'block', fontSize: '60px' }} />}
        </div>
        <Footer style={{ textAlign: 'center' }}>
          <p style={{ margin: 0 }}>SongRepo - <a href="https://github.com/VBoureaud/">VBoureaud</a></p>
          <p style={{ margin: 0 }}>v1.0.0</p>
        </Footer>
      </div>
  )

  return scenePublic;
}

export default PublicScene;